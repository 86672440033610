import { defineMessage } from '@lingui/macro'
import makeUrl from 'components/src/makeUrl'

import { getActiveStoreView, setStoreViews, StoreView } from '@emico/storeviews'

const storeViews: Array<
    StoreView & {
        prismicLocale?: string
    }
> = [
    {
        code: 'outlet_pme_en',
        language: 'en',
        locale: 'en-gb',
        name: defineMessage({
            id: 'core.storeConfig.outlet_pme_en',
            message: `International`,
        }),
        location: undefined,
        makeUrl: makeUrl(),
        basename: '',
        default: true,
        storeId: 57,
        websiteId: 25,
        prismic: 'pme_outlet',
        prismicLocale: 'en-gb',
    },
    {
        code: 'outlet_pme_nl',
        language: 'nl',
        locale: 'nl-nl',
        name: defineMessage({
            id: 'core.storeConfig.outlet_pme_nl',
            message: `Netherlands`,
        }),
        location: (location) =>
            location.pathname === '/nl' || location.pathname.startsWith('/nl/'),
        makeUrl: makeUrl('nl'),
        basename: '/nl',
        default: false,
        storeId: 58,
        websiteId: 25,
        prismic: 'pme_outlet',
        prismicLocale: 'nl-nl',
    },
    {
        code: 'outlet_pme_de',
        language: 'de',
        locale: 'de-de',
        name: defineMessage({
            id: 'core.storeConfig.outlet_pme_de',
            message: `Germany`,
        }),
        location: (location) =>
            location.pathname === '/de' || location.pathname.startsWith('/de/'),
        makeUrl: makeUrl('de'),
        basename: '/de',
        default: false,
        storeId: 60,
        websiteId: 27,
        prismic: 'pme_outlet',
        prismicLocale: 'de-de',
    },
    {
        code: 'outlet_pme_be',
        language: 'nl',
        locale: 'nl-be',
        name: defineMessage({
            id: 'core.storeConfig.outlet_pme_be',
            message: `Belgium`,
        }),
        location: (location) =>
            location.pathname === '/be' || location.pathname.startsWith('/be/'),
        makeUrl: makeUrl('be'),
        basename: '/be',
        default: false,
        storeId: 59,
        websiteId: 26,
        prismic: 'pme_outlet',
        prismicLocale: 'nl-be',
    },
]

const activeStoreView = getActiveStoreView(storeViews, window.location)
setStoreViews(storeViews, activeStoreView)

export default storeViews
