import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { LoginError } from 'components/src/AccountAccessForm'
import { Email } from 'components/src/input/Email'
import FormGroup from 'components/src/input/FormGroup'
import { Label } from 'components/src/input/Label'
import { Password } from 'components/src/input/Password'
import Submit from 'components/src/input/Submit'
import SuccessMessage from 'components/src/input/SuccessMessage'
import Container from 'components/src/layout/Container'
import Link from 'components/src/navigation/Link'
import paths from 'components/src/paths'
import Heading from 'components/src/typography/Heading'
import { useLoginForm } from 'components/src/useLoginForm'
import validateRequired from 'components/src/utils/validateRequired'
import { Form } from 'informed'

import styles from './AccountLoginPageLayout.module.scss'

const AccountLoginPageForm = () => {
    const { handleSubmit, error, isLoading } = useLoginForm({
        pushEventData: {
            formName: 'Login',
            formID: 'account.login',
        },
        redirect: paths.account,
    })

    return (
        <Container>
            <Form onSubmit={handleSubmit} noValidate data-cy="loginform">
                <LoginError error={error} />
                <SuccessMessage />
                <Heading
                    variant="h3"
                    element="h2"
                    color="dark"
                    className={styles.heading}
                >
                    <Trans id="account.login.formHeading">
                        Login to your account
                    </Trans>
                </Heading>
                <FormGroup>
                    <Label className={styles.label}>
                        <Trans id="account.login.emailLabel">
                            Email address
                        </Trans>
                        <Email field="username" />
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Label className={styles.label}>
                        <Trans id="account.login.passwordLabel">Password</Trans>
                        <Password
                            field="password"
                            validate={validateRequired(
                                t({
                                    id: 'account.login.passwordRequiredMessage',
                                    message: `Please enter your password`,
                                }),
                            )}
                            autoComplete="current-password"
                            validateStrength={false}
                        />
                    </Label>
                </FormGroup>
                <div className={styles.formButtonWrapper}>
                    <Submit
                        variant="primary"
                        wide
                        name="Log in"
                        category="user.loginForm.submit"
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        <Trans id="account.login.submit">Log in</Trans>
                    </Submit>
                    <Link
                        name="Forgot passsord?"
                        category="account.login"
                        to={paths.forgotPassword}
                        className={styles.forgotPasswordLink}
                    >
                        <Trans id="account.login.toForgotPassword">
                            Forgot your password?
                        </Trans>
                    </Link>
                </div>
            </Form>
        </Container>
    )
}

export default AccountLoginPageForm
